import Enum from "@/util/enum";
import EnumValue from ".";

export default class News extends Enum {
  static NULL: EnumValue = { name: "", code: 0 };
  static DON_UPDATES: EnumValue = {
    name: "There are updates in donors!",
    code: 1,
  };
  static RESULTS: EnumValue = { name: "New typing results", code: 2 };
  static RESERVE_CONFIRMED: EnumValue = {
    name: "Reservation has been confirmed",
    code: 3,
  };
  static RESERVE_DECLINED: EnumValue = {
    name: "Reservation has been declined",
    code: 4,
  };
  static MULTIPLE_UPDATES: EnumValue = { name: "Multiple updates", code: 5 };
}
